<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header">
              Movimientos
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-success badge-pill float-right sdw">
                    <i class="fas fa-exchange-alt text-white"></i>
                  </span>
                </template>
                <span>Detalle Movimiento</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <v-container>
                <!-- 2°second ROW -->
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <ul class="list-group mb-3" v-for="item in movement" :key="item.id">
                      <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">{{item.name}}</span>
                        <span class="badge badge-secondary badge-pill">ID: {{id}}</span>
                      </h4>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Movimiento: {{item.name}}</h6>
                          <small
                            class="text-muted"
                          >Operacion de traslado de productos a otras bodegas.</small>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fas fa-exchange-alt text-white"></i>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card:hover,
.sdw {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  color: black;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    boolean: false,
    url: "http://localhost/apiagunsaoms/agunsa-api-oms/public/api/",
    dialog: false,
    movement: [
      {
        id: 3,
        name: "Movimiento de productos",
      },
    ],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Movimientos", route: "/movement" },
      {
        title: "➁ Detalle Movimiento",
        route: `/movement/movement-detail/${this.id}`,
      },
    ]);
  },
  created() {
    //this.fetchMovement();
  },
  methods: {
    initialize() {},
    fetchMovement() {
      var vm = this;
      this.axios({
        url: "inventory/movement/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          //vm.movement = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>